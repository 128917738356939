import {
  Mail01,
  Plus,
  UserPlus01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
// import moment from 'moment';
import { find, findIndex, isEqual, map, maxBy } from 'lodash';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { inviteMember } from 'src/services/OrganizationService';
import WalToast from 'src/utils/WalToast';

const addMoreArr = [
  {
    id: 1,
    email: '',
    organization_id: '',
    role: null,
    emailErrorMsg: '',
    roleErrorMsg: '',
  },
];

const userRole = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
];

const AddMember = ({ handleCloseMember, getOrganizationData }: any) => {
  const [addMoreInvites, setAddMoreInvites] = useState(addMoreArr);
  const [isSaveMemberLoading, setIsSaveMemberLoading] = useState(false);

  const handleMultiInputChange = (e: any, index: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(index) });
    const newUpdate = JSON.parse(JSON.stringify(addMoreInvites));
    newUpdate[ind].email = e.target.value;
    setAddMoreInvites(newUpdate);
  };

  const handleSelectChange = (e: any, index: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(index) });
    const newUpdate = JSON.parse(JSON.stringify(addMoreInvites));
    newUpdate[ind].role = e.value;
    setAddMoreInvites(newUpdate);
  };

  const handleSelected = (id: any) => {
    const ind = findIndex(addMoreInvites, { id: parseInt(id) });

    return userRole?.find(
      (val: any) => val.value === addMoreInvites?.[ind]?.role
    );
  };

  const handleRemoveMember = (id: any) => {
    const newObj = addMoreInvites.filter((member: any) => member?.id !== id);
    setAddMoreInvites(newObj);
  };

  const addNewInvites = () => {
    const maxId = maxBy(addMoreInvites, 'id') as any;
    const newObj = {
      id: maxId?.id + 1 || 1,
      email: '',
      role: '',
      errorMsg: '',
    };
    setAddMoreInvites((s: any) => [...s, newObj]);
  };

  const validMemberForm = () => {
    let valid = true;

    if (addMoreInvites?.length > 0) {
      const arr = addMoreInvites;
      addMoreInvites?.map((v: any, index: any) => {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        arr[index].emailErrorMsg = '';
        arr[index].roleErrorMsg = '';

        if (v?.email === undefined || v?.email === '' || v?.email === null) {
          arr[index].emailErrorMsg = 'Email is required';
          valid = false;
        } else if (
          addMoreInvites[index]?.email &&
          !emailRegex.test(addMoreInvites[index]?.email)
        ) {
          arr[index].emailErrorMsg = 'Please enter valid email address';
          valid = false;
        }

        if (v?.role === undefined || v?.role === '' || v?.role === null) {
          arr[index].roleErrorMsg = 'Role is required';
          valid = false;
        }

        return valid;
      });

      map(addMoreInvites, function (o: any, i) {
        var eq = find(addMoreInvites, function (e: any, ind) {
          if (i > ind) {
            return isEqual(e.email, o.email);
          }
        });

        if (eq) {
          if (o.emailErrorMsg === '') {
            o.emailErrorMsg = 'This email address is already added in the list';
            valid = false;
          }

          return o;
        } else {
          return o;
        }
      });

      setAddMoreInvites(() => [...arr]);
    }

    return valid;
  };

  const handleSaveMember = () => {
    console.log('object');

    if (!validMemberForm()) {
      return true;
    }
    setIsSaveMemberLoading(false);
    const payload = addMoreInvites?.map((member: any) => ({
      email: member?.email,
      role: member?.role,
    }));

    inviteMember({ inviteMembers: JSON.stringify(payload) })
      .then((response: any) => {
        if (response?.status) {
          handleCloseMember();
          getOrganizationData();
          WalToast.success('Member Invitation', 'Member successfully invited.');
        } else {
          WalToast.error(
            'Error: Member Invitation',
            'Error encountered while inviting the member. Please try again.'
          );
        }
      })
      .finally(() => {
        setIsSaveMemberLoading(false);
      })
      .catch(() => {
        setIsSaveMemberLoading(false);
        WalToast.error(
          'Error: Member Invitation',
          'Error encountered while inviting the member. Please try again.'
        );
      });
  };

  return (
    <>
      <CommonModal
        title={'Invite an organization member'}
        titleDesc={'Allow access to more members of your organization.'}
        handleClose={handleCloseMember}
        headerIcon={<UserPlus01 />}
        secondaryBtnOnClick={() => handleCloseMember()}
        size={'max-w-[488px]'}
        primaryBtnText={'Save changes'}
        primaryBtnOnClick={handleSaveMember}
        primaryBtnLoading={isSaveMemberLoading}
        secondaryBtnText="Cancel"
        isOverflow={true}
      >
        <div className="">
          <div className="p-3.5 md:p-4 xxl:p-5">
            {addMoreInvites?.map((item, i) => (
              <div
                className="flex flex-col w-full justify-between md:gap-3 md:flex-row "
                key={i}
              >
                <div className="w-full md:w-auto flex flex-row justify-between  ">
                  {/* {i === 0 && <label className={`form_label`}>Email address</label>} */}
                  {/* <label className={`form_label`}>Email address</label> */}
                  <div className="relative">
                    <InputText
                      inputName="email"
                      className={`appearance-none block pl-[38px] h-[40px] max-h-[40px] placeholder:text-gray500 text-sm font-normal w-full input_border rounded-lg shadow-sm placeholder:text-sm text-[#000000E5] placeholder:font-normal ${
                        item.emailErrorMsg
                          ? ' border border-red-500 rounded-lg '
                          : ''
                      }`}
                      label="Email address"
                      placeholder="Enter invitee's email address"
                      value={item?.email || ''}
                      onChangeFunc={(e) => handleMultiInputChange(e, item?.id)}
                      id={`email_${item?.id}`}
                      errorText={
                        item?.emailErrorMsg ? item?.emailErrorMsg : null
                      }
                    />
                    <Mail01 className="absolute left-2.5 top-10" />
                  </div>
                </div>
                <div className=" w-full md:w-auto flex flex-row justify-between  ">
                  <div className="w-full cursor-pointer select_box_org">
                    <label className={`form_label`}>Role</label>
                    <SelectBox
                      name="role"
                      placeholder="Select Role"
                      className={`form_control cursor-pointer`}
                      options={userRole}
                      id={`role_${item?.id}`}
                      classComp={`${
                        item.roleErrorMsg && !item?.role
                          ? 'border border-red-500 rounded-lg'
                          : ' input_border drop-down-border rounded-lg'
                      }`}
                      onChangeFunc={(e) => handleSelectChange(e, item?.id)}
                      value={handleSelected(item?.id)}
                      errorText={
                        item.roleErrorMsg && !item?.role
                          ? item.roleErrorMsg
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" w-full md:w-auto flex flex-row justify-between ">
                  {i > 0 && (
                    <button
                      disabled={addMoreInvites?.length <= 1}
                      className={`border-none ${
                        addMoreInvites?.length <= 1
                          ? 'cursor-not-allowed'
                          : 'cursor-pointer'
                      }`}
                      // className=" cursor-pointer  text-fgErrorPrimary !border-0 !p-0 w-9 h-9"
                      onClick={() => handleRemoveMember(item?.id)}
                    >
                      <Trash01 className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </div>
            ))}

            <div className="flex px-1 pt-1">
              <div className="w-full p-0 text-left">
                <div className="flex">
                  <p
                    className="text-[#2422DD] cursor-pointer flex text-xs font-semibold"
                    onClick={() => addNewInvites()}
                  >
                    <Plus className="pr-1 w-5" />
                    Add Member
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AddMember;
