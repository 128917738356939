import React, { useContext, useEffect, useState } from 'react';

import AutoCompleteGoogle from '../../components/AutoCompleteGoogle/AutoCompleteGoogle';
// import ButtonCmp from '../../components/ButtonCmp';
import InputText from '../../components/InputText/InputText';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import { scriptId } from '../../constants/common';
import { AuthContext } from '../../context/AuthContext';
import { BasicContext } from '../../context/BasicContext';
import { getDetail } from '../../services/AuthService';

import OrganizationMembers from './components/OrganizationMembers';

const Organization = () => {
  const { googleKey } = useContext(BasicContext);
  const { currentUser } = useContext(AuthContext);
  const [address, setAddress] = useState<any>(null);
  const [fullAddress, setFullAddress] = useState<any>(null);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  useEffect(() => {
    document.getElementById(scriptId)?.remove();
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, [googleKey]);

  window.initMap = () => {
    setStatus(true);
  };

  const [companyLogo, setCompanyLogo] = useState<any>(null);
  const [formData, setFormData] = useState<any>({
    company_name: '',
    company_logo: null,
    company_no: '',
  });

  useEffect(() => {
    getDetail(currentUser?.customerId?.id)
      .then((response) => {
        setFormData({
          ...formData,
          company_name: response?.data?.company_name,
          company_logo: `${response?.data.image}${response?.data.imageUrl}`,
          company_no: response?.data.companyPhoneNumber,
        });
        setFullAddress(response?.data?.company_address);

        const defaultCompanyImage = `${response?.data.imageUrl}${response?.data.image}`;
        setCompanyLogo(defaultCompanyImage || null);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [currentUser]);

  const handleNumberChange = (
    country: any,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    console.log('🚀 ~ Organization ~ isValid:', isValid);
    setFormData({
      ...formData,
      company_no: phone === '' || phone === null ? '' : `+${code}${phone}`,
      phone_country_code: country,
    });

    // if ((phone !== '' || phone !== null) && isValid) {
    //   setValidForm({
    //     ...validForm,
    //     [`step${currentStep}`]: {
    //       ...validForm[`step${currentStep}`],
    //       [currentStep === 1 ? 'contact_no' : 'company_no']: true,
    //       [currentStep === 1 ? 'contact_no_valid' : 'company_no_valid']:
    //         isValid,
    //     },
    //   });
    // } else {
    //   setValidForm({
    //     ...validForm,
    //     [`step${currentStep}`]: {
    //       ...validForm[`step${currentStep}`],
    //       [currentStep === 1 ? 'contact_no_valid' : 'company_no_valid']:
    //         isValid,
    //     },
    //   });
    // }
  };

  return (
    <>
      <section className="main__layout_section flex flex-col ">
        <div className="header_sticky  xxl:px-5 px-5 xxl:pt-5 pt-5 pb-4">
          <div className="flex border-b flex-col md:flex-row lg:flex-row justify-between items-center xl:flex-row  pb-4 ">
            <div className="flex flex-col w-full md:w-[80%]">
              <div className="flex">
                <div className="title_text_page">Team Members</div>
              </div>
              <div className="subtitle_text_page ">
                Overview and Management of Your Team Members
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between mx-5 xxl:mx-5 py-3 border-b">
          <div className="w-full md:w-[30%]">
            <div className="">
              <p className="text-sm text-[#344054] font-semibold">
                Organization
              </p>
              <p className="text-xs text-grayLight600 font-normal">
                Here you can change your organization photo
              </p>
            </div>
          </div>
          <div className="w-full md:w-[70%]">
            <div className="flex flex-col sm:flex-nowrap flex-wrap ">
              <div className="sm:mt-0 mt-1 rounded-md  flex  justify-center text-sm items-center lg:w-1/2 pb-7">
                <div className="gap-2 flex mt-4 w-full">
                  <div className="w-[52px] md:h-[52px] rounded-full">
                    {/* {!isLoading && <>
                                                {!orgLogo && (chooseOrgImage === null || chooseOrgImage === '') && <img src={formData?.org_logo} alt="" className='rounded-full object-cover md:h-[52px] w-[52px] mt-1 Tes1' onError={onError} />}
                                                {orgLogo && !chooseOrgImage && <img src={URL.createObjectURL(orgLogo || null)} alt="" className='rounded-full object-cover md:h-[52px] w-[52px] mt-1 Tes2' />}
                                                {!orgLogo && chooseOrgImage > 0 && <img src={`${MEDIA_URL}qemassets/org-icon/logo_${chooseOrgImage + 1}.png`} alt="" className='rounded-full object-cover md:h-[52px] w-[52px] mt-1 Tes3' />}
                                                {!orgLogo && chooseOrgImage === 0 && <img src={`${MEDIA_URL}qemassets/org-icon/logo_${chooseOrgImage + 1}.png`} alt="" className='rounded-full object-cover md:h-[52px] w-[52px] mt-1 Tes4' />}
                                            </>
                                            } */}
                    <img
                      src={companyLogo}
                      className="object-cover md:h-[52px] w-[52px] mt-1"
                    />
                  </div>
                  <label
                    htmlFor="feedbackImage"
                    className="p-3 md:ml-3 rounded-xl cursor-pointer bg-white border border-gray100 flex items-center justify-center w-full lg:w-[80%] h-[74px]"
                  >
                    <div className="content flex flex-col justify-center items-center">
                      <h1 className="text-sm">
                        <span className="text-primary text-sm font-semibold">
                          Click to upload{' '}
                        </span>
                        <span className="text-grayLight600 text-sm font-normal">
                          {' '}
                          or Drag & Drop or
                        </span>
                      </h1>
                      <p className="text-center text-xs text-grayLight600 mt-1 font-normal">
                        PNG, JPG or GIF (max 800x800px)
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="sm:mt-0 mt-1 rounded-md  flex p-1 justify-center ">
                <div className="flex flex-col md:flex-row w-full md:gap-3">
                  <div className="w-full md:mb-0">
                    <InputText
                      className="appearance-none block form_input"
                      inputName="company_name"
                      label="Organization Name"
                      placeholder="Enter organization name"
                      value={formData?.company_name}
                      // onChangeFunc={handleInputChange}
                      // errorText={!validForm.org_name ? 'Organization Name is required' : null}
                    />
                  </div>
                  <div className="w-full md:mb-0">
                    <label className="form_label">Organization Address</label>
                    <div className="relative flex justify-between">
                      <div className="flex-1">
                        {status && (
                          <AutoCompleteGoogle
                            inputName="company_address"
                            placeholder="Enter company address"
                            className="form_control truncate"
                            // label="Address"
                            labelClassName="mb-1.5 block"
                            onChangeFunc={(e: any) => {
                              if (e) {
                                setFullAddress(e.fullAddress);
                                setAddress(e);
                                // setValidForm({
                                //   ...validForm,
                                //   [`step${currentStep}`]: {
                                //     ...validForm[`step${currentStep}`],
                                //     ['company_address']:
                                //       fullAddress?.trim() !== '',
                                //   },
                                // });
                                console.log(address, fullAddress);
                              }
                            }}
                            onAddressInputChange={(e: any) => {
                              setFullAddress(e);
                            }}
                            // errorText={
                            //   !validForm.company_address
                            //     ? 'Company Address is required'
                            //     : null
                            // }
                            value={fullAddress}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:mb-0">
                    <label className="form_label">Contact Number</label>
                    <div className="relative flex justify-between">
                      <div className="flex-1">
                        <PhoneInput
                          onNumberChange={handleNumberChange}
                          name="phone"
                          // errors={
                          //   !validForm.company_no_valid
                          //     ? 'Invalid Number'
                          //     : !validForm.company_no
                          //     ? 'Phone number is required'
                          //     : null
                          // }
                          value={2525252525}
                          defaultValues={{
                            phone: 2525252525,
                            phone_country_code: 'CA',
                          }}
                        />
                        {formData?.company_no}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OrganizationMembers />
      </section>
    </>
  );
};

export default Organization;
