import {
  Check,
  ClockFastForward,
  Edit01,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from 'src/constants/common';

import BadgeCmp from '../../../../components/BadgeCmp';
import TooltipCmp from '../../../../components/TooltipCmp';
import { getShortName, onError } from '../../../../utils/CommonFunctions';

interface IProps {
  data: any;
  keyValue: string;
  handleActionType: any;
}

const MemberRow = ({ data, handleActionType }: IProps) => (
  <>
    <td className=" px-5 py-4 w-80 min-w-80 max-w-80">
      <div className="flex items-center">
        <div className="table-profile-wrap notification-mark-wrap">
          {data.image ? (
            <img
              className="table-profile-icn-circle-xl"
              src={data.imageUrl + data.image}
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="table-profile-user-circle-xl">
              {getShortName(`${data?.name || data?.email}`)}
            </div>
          )}
        </div>
        <div className="ml-3 max-w-40">
          {data?.name && (
            <h6 className="text-grayLight900 font-medium leading-tight truncate">{`${data.name}`}</h6>
          )}
          <p className="text-grayLight600 font-normal leading-tight truncate">{`${data.email}`}</p>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 w-25 max-w-25 min-w-56 ">
      <div className="flex">
        {data.isActive ? (
          <BadgeCmp style="pill" type="success">
            <Check className="text-green500 mr-1 h-3 w-3" /> Active
          </BadgeCmp>
        ) : (
          <BadgeCmp style="pill" type="warning">
            <ClockFastForward className="mr-1 h-3 w-3 text-lg text-warning500" />{' '}
            Pending
          </BadgeCmp>
        )}
      </div>
    </td>
    <td className="px-5 py-4 w-25 max-w-25 min-w-56 ">
      <span>
        {data?.lastLoginAt ? moment(data.lastLoginAt).format(DATE_FORMAT) : '-'}
      </span>
    </td>
    <td className="px-5 py-4 w-25 max-w-25 min-w-[26rem]">
      <div className="flex">
        {data?.role || '-'}
        {/* {roles && roles.length > 2 ? (
            <div className="rounded-full bg-utilityGray100 ">
              <p className="text-grayLight600 text-xs font-semibold w-6 h-5 flex items-center justify-center flex-none">
                +{roles.length - 2}
              </p>
            </div>
          ) : (
            false
          )} */}
      </div>
    </td>
    <td className="px-5 py-4 ">
      <div className="items-center flex gap-4 justify-end">
        {!data?.isMainUser && (
          <>
            <TooltipCmp message={'Delete Member'}>
              <Trash01
                className="w-4 h-4 relative cursor-pointer"
                onClick={handleActionType('member', 'member-delete', data)}
              />
            </TooltipCmp>
            <TooltipCmp message={'Edit Member'}>
              <Edit01
                className="w-4 h-4 relative cursor-pointer "
                onClick={handleActionType('member', 'member-edit', data)}
              />
            </TooltipCmp>
          </>
        )}
      </div>
    </td>
  </>
);

export default MemberRow;
