import { ImagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import ButtonCmp from '../../components/ButtonCmp';
import ErrorMsg from '../../components/errorMsg';
import InputText from '../../components/InputText/InputText';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
// import { BasicContext } from '../../context/BasicContext';
import { saveBasicDetails } from '../../services/AuthService';
import AuthWrapper from '../AppLayout/AuthWrapper';

// Custom validation functions
const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

const stepTitle = {
  title: 'Create your Account',
  subtitle: 'Provide Your Personal and Contact Information.',
} as any;

const SaveBasicDetails = () => {
  const { setIsAuthenticated, currentUser, setIsUpdateProfile, isMainUser } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<any>(null);

  const [formData, setFormData] = useState<any>({
    name: currentUser?.name || '',
    profile_image: '',
    title: currentUser?.title || '',
    contact_email: currentUser?.email || '',
    contact_no: currentUser?.phone || '',
    phone_country_code: '',
  });

  useEffect(() => {
    if (currentUser?.isSetBasicDetail) {
      navigate(ROUTES.HOME);
    }

    if (isMainUser) {
      navigate(ROUTES.SET_DETAILS);
    }
    const defaultProfileImage = currentUser?.image
      ? `${currentUser?.imageUrl}${currentUser?.image}`
      : null;
    setProfileImage(defaultProfileImage || null);
    setFormData({
      name: currentUser?.name || '',
      profile_image: '',
      title: currentUser?.title || '',
      contact_email: currentUser?.email || '',
      contact_no: currentUser?.phone || '',
      phone_country_code: '',
    });
  }, [currentUser]);

  const [validForm, setValidForm] = useState<any>({
    name: true,
    title: true,
    contact_email: true,
    contact_no: true,
    contact_no_valid: true,
    profile_image: true,
    profile_image_size_valid: true,
  });

  // Validation function for each step
  const validateStep = () => {
    let isValid = true;
    const newValidForm = { ...validForm };

    // Step 1 Validation

    if (!formData?.name || formData?.name?.trim() === '') {
      newValidForm.name = false;
      isValid = false;
    } else {
      newValidForm.name = true;
    }

    if (!formData?.title || formData?.title?.trim() === '') {
      newValidForm.title = false;
      isValid = false;
    } else {
      newValidForm.title = true;
    }

    if (!validateEmail(formData.contact_email)) {
      newValidForm.contact_email = false;
      isValid = false;
    } else {
      newValidForm.contact_email = true;
    }

    if (profileImage === null || profileImage === '') {
      newValidForm.profile_image = false;
      isValid = false;
    } else {
      newValidForm.profile_image = true;
    }

    if (formData.contact_no === null || formData.contact_no === '') {
      newValidForm.contact_no = false;
      isValid = false;
    } else {
      newValidForm.contact_no = true;
    }
    setValidForm(newValidForm);

    return isValid;
  };

  // Handle input change
  const handleInputChange = (event: any) => {
    setFormData({ ...formData, [event?.target?.name]: event?.target?.value });

    // Live validation for the current field
    setValidForm({
      ...validForm,
      [event?.target?.name]: event?.target?.value.trim() !== '',
    });
  };

  const hasAnyFormValueFalse = (object: any) => {
    for (let key in object) {
      if (key === 'profile_image' || key === 'phone_country_code') {
        continue;
      }

      if (!object[key]) {
        return false;
      }
    }

    return true;
  };

  const convertToFormDataWithImages = async (formDataStructure: any) => {
    const formValueData = new FormData();

    for (const step in formDataStructure) {
      if (step === 'profile_image') {
        continue;
      }
      formValueData.append(`${step}`, formDataStructure[step]);
      // await appendStepData(step);
    }

    return formValueData;
  };

  // Final form submission
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log('🚀 ~ handleSubmit ~ payload:', formData);
    if (!validateStep() || !hasAnyFormValueFalse(formData)) return;

    const payload = await convertToFormDataWithImages(formData);
    console.log('🚀 ~ handleSubmit ~ payload:', payload);
    payload.append(`customer_logo`, formData?.profile_image as any);

    saveBasicDetails(payload)
      .then((response: any) => {
        if (response?.status) {
          setIsAuthenticated(true);
          setIsUpdateProfile(true);
          navigate(ROUTES.HOME);
        }
      })
      .catch((error: any): any => {
        console.log('🚀 ~ handleSubmit ~ error:', error);
      });
    // Handle final form submission logic here
    console.log('Form submitted:', payload);
  };

  const handleNumberChange = (
    country: any,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    // setFormData({
    //   ...formData,
    //   ['contact_no']: phone === '' || phone === null ? '' : `+${code}${phone}`,
    // });

    setFormData((old: any) => ({
      ...old,
      contact_no: phone === '' || phone === null ? '' : `+${code}${phone}`,
      phone_country_code: country,
    }));

    // setFormData({ ...formData, ['phone_country_code']: country });

    if ((phone !== '' || phone !== null) && isValid) {
      setValidForm({
        ...validForm,
        ...{ contact_no: true, contact_no_valid: isValid },
      });
    } else {
      setValidForm({ ...validForm, contact_no_valid: isValid });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      setProfileImage(null);

      if (file.type.match('image')) {
        setFormData({ ...formData, ['profile_image']: file });
        setValidForm({ ...validForm, ['profile_image']: true });
      } else {
        setValidForm({ ...validForm, ['profile_image']: false });
      }

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();

          img.onload = () => {
            if (img.width > 800 || img.height > 800) {
              setValidForm({
                ...validForm,
                ['profile_image_size_valid']: false,
              });
            } else {
              setValidForm({
                ...validForm,
                ['profile_image_size_valid']: true,
              });
            }

            setProfileImage(img.src);
          };
          img.src = e.target!.result as string;
        };
        reader.readAsDataURL(file);
      }
    },
    [formData, validForm]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/svg+xml': ['.svg'],
    },
  });

  return (
    <AuthWrapper>
      <div className="form_height pt-8">
        <div className="w-full">
          <div className="flex-1 w-full">
            <h1 className="title_text">{stepTitle?.title}</h1>
            <p className="text-sm mt-1 font-normal text-[#475467]">
              {stepTitle?.subtitle}
            </p>
            <form onSubmit={handleSubmit} className="h-[100%]">
              {/* Step 1 - Personal Information */}

              <div className="flex flex-col">
                <label className="form_label text-[#000000B2]">
                  Profile Image
                </label>
                <div className="position-relative flex-1 w-50">
                  <div className="flex md:gap-4 gap-2 md:flex-row flex-col items-center">
                    {profileImage !== null ? (
                      <img
                        className="w-14 h-14 border-[0.75px] border-black/[0.08] rounded-full object-cover"
                        src={profileImage}
                        alt={formData.firstName}
                        title={formData.firstName}
                      />
                    ) : (
                      <label className="w-14 h-14 border border-black/[0.08] rounded-full object-cover flex justify-center items-center bg-utilityGray100 text-gray500">
                        <ImagePlus />
                      </label>
                    )}
                    <div className="relative z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border border-utilityGray200 py-3.5 px-5 text-center bg-white">
                      <label
                        className="cursor-pointer"
                        htmlFor="fileSelect"
                        {...getRootProps()}
                      >
                        <progress
                          className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                          value="70"
                          max="100"
                        ></progress>
                        <div className="text-grayLight600 text-xs font-normal">
                          <p>
                            <span className="text-[#2422DD] font-semibold pr-1">
                              Click to upload{' '}
                            </span>
                            or drag and drop
                          </p>
                          <p className="text-[11px] leading-[18px]">
                            SVG, PNG or JPG (max. 800x800px)
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name="image"
                        id="fileSelect11"
                        className="hidden"
                        {...getInputProps()}
                      />
                    </div>
                  </div>
                  <div className="pb-1">
                    {!validForm.profile_image && (
                      <ErrorMsg errorText={'Profile Image is required.'} />
                    )}
                    {!validForm.profile_image_size_valid && (
                      <ErrorMsg
                        errorText={
                          'Image dimensions must be less than or equal to 800 x 800'
                        }
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex flex-col">
                <label className="form_label text-[#000000B2]">Name</label>
                <InputText
                  inputName="name"
                  className={`input_text_auth${
                    !validForm.name
                      ? ' !border-[#FDA29B] '
                      : ' !border-[#D0D5DD] '
                  }`}
                  placeholder="Your Name"
                  value={formData?.name}
                  onChangeFunc={handleInputChange}
                />
                {!validForm.name && (
                  <p className="text-sm text-[#D92D20]">Name is required</p>
                )}
              </div>

              <div className="flex flex-col">
                <label className="form_label text-[#000000B2]">Title</label>
                <InputText
                  inputName="title"
                  className={`input_text_auth${
                    !validForm.title
                      ? ' !border-[#FDA29B] '
                      : ' !border-[#D0D5DD] '
                  }`}
                  placeholder="Your Title"
                  value={formData?.title}
                  onChangeFunc={handleInputChange}
                />
                {!validForm.title && (
                  <p className="text-sm text-[#D92D20]">Title is required</p>
                )}
              </div>

              <div className="flex flex-col">
                <label className="form_label text-[#000000B2]">
                  Contact Email
                </label>
                <InputText
                  inputName="contact_email"
                  className={`input_text_auth${
                    !validForm.contact_email
                      ? ' !border-[#FDA29B] '
                      : ' !border-[#D0D5DD] '
                  }`}
                  placeholder="Email"
                  value={formData?.contact_email}
                  onChangeFunc={handleInputChange}
                  disabled={true}
                />
                {!validForm.contact_email && (
                  <p className="text-sm text-[#D92D20]">
                    Valid email is required
                  </p>
                )}
              </div>

              <div className="flex flex-col">
                <label className="form_label text-[#000000B2]">
                  Contact No
                </label>

                <PhoneInput
                  onNumberChange={handleNumberChange}
                  name="contact_no"
                  errors={
                    !validForm.contact_no_valid
                      ? 'Invalid Number'
                      : !validForm.contact_no
                      ? 'Phone number is required'
                      : null
                  }
                  value={formData?.contact_no || ''}
                  defaultValues={{
                    phone: formData?.contact_no || '',
                    phone_country_code: formData?.phone_country_code,
                  }}
                />
              </div>
              {/* Navigation Buttons */}
              <div className="flex justify-between mt-4">
                <ButtonCmp type="submit" className="btn_primary">
                  Save
                </ButtonCmp>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default SaveBasicDetails;
