import { Plus, SearchLg, UserPlus01 } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import BadgeCmp from '../../../components/BadgeCmp';
import ButtonCmp from '../../../components/ButtonCmp';
import CommonModal from '../../../components/CommonModal';
import CustomPagination from '../../../components/CustomPagination';
import DeleteModal from '../../../components/DeleteModal';
import InputText from '../../../components/InputText/InputText';
import NotFoundUI from '../../../components/NotFoundUI';
import SelectBox from '../../../components/SelectBox/SelectBox';
import TableCmp from '../../../components/TableCmp';
// import TooltipCmp from '../../../components/TooltipCmp';
import {
  deleteMember,
  editMember,
  fetchOrganizationMemberList,
} from '../../../services/OrganizationService';
import WalToast from '../../../utils/WalToast';

import AddMember from './AddMember';
import MemberRow from './MemberRow';
import LoadingRow from './MemberRow/LoadingRow';
// import WalToast from '../../../utils/WalToast';
// import { BasicContext } from '../../../context/BasicContext';
// import { upperFirst } from 'lodash';
// import checkrighticon from '../../../assets/img/checkright.svg';
// import pendingicon from '../../../assets/img/pendingicon.svg';

const userRole = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
];

const recordsPerPageArray = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
];
// const initFormData: any = {
//   member_id: '',
//   role: '',
// };

const initParams = {
  search: '',
  page: 1,
  limit: 5,
  role: '',
  sortType: 'asc',
  sortField: 'name',
};
const initAction = {
  mode: null,
  team: false,
};

const OrganizationMembers = () => {
  // const [formData, setFormData] = useState<any>(initFormData);
  const [orgMember, setOrgMember] = useState<any>([]);
  const [adminParams, setAdminParams] = useState(initParams);
  const [searchInput, setSearchInput] = useState('');

  // const [openEditMember, setOpenEditMember] = useState(false);
  const [isMemberLoading, setIsMemberLoading] = useState(false);
  const [isEditMemberLoading, setIsEditMemberLoading] = useState(false);
  const [totalMembers, setTotalMembers] = useState(0);
  const [action, setAction] = useState(initAction);
  const [member, setMember] = useState<any>(null);
  const [openAddOrgMember, setOpenAddOrgMember] = useState<any>(false);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Name',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'role',
        name: 'Role',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'lastActive',
        name: 'Last Active',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'isActive',
        name: 'Status',
        sortable: true,
        rowClassName: '',
      },
      {
        id: 'action',
        sortable: false,
        rowClassName: '',
      },
    ],
    []
  );

  const handleAdminsPagination = (page: number) => {
    setAdminParams((old) => ({ ...old, page }));
  };

  const getOrganizationData = () => {
    setIsMemberLoading(true);
    fetchOrganizationMemberList({ ...adminParams })
      .then((response: any) => {
        setOrgMember(response?.data || []);
        setTotalMembers(response?.total || 0);
      })
      .finally(() => {
        setIsMemberLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    getOrganizationData();
  }, [
    adminParams?.page,
    adminParams?.search,
    adminParams?.limit,
    adminParams?.role,
  ]);

  const adminSearchDebounce = useCallback(
    debounce(
      (search: string) =>
        // setAdminParams(() => ({ ...init, ...{ search: search } })),
        setAdminParams((old: any) => ({
          ...old,
          search: search || '',
        })),
      700
    ),
    []
  );

  const handleAdminsFilter = (event: any) => {
    setSearchInput(event.target.value);
    adminSearchDebounce(event.target.value);
  };

  const handleActionType =
    (keyAction: any, mode: any = null, memberRec: any = {}) =>
    () => {
      setMember(memberRec);
      setAction((old) => ({ ...old, [keyAction]: true, mode }));
    };

  const handleCloseModel = useCallback(() => {
    // setUserCreateFormData({});
    setAction(initAction);
    setMember(null);
  }, []);

  const handleCloseMember = useCallback(() => {
    setOpenAddOrgMember(false);
  }, []);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);
      setMember(null);

      if (status) {
        deleteMember(member?.id)
          .then(() => {
            getOrganizationData();
            // setIsRefresh(true);
            WalToast.success(
              `${member?.name ?? 'Member'}
                 was removed from the organization.`
            );
          })
          .catch((e) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting member account.'
            );
          });
      }
    },
    [member]
  );

  const handleEditMember = () => {
    setIsEditMemberLoading(true);
    console.log(member);
    editMember({ id: member?.id, role: member?.role })
      .then((response: any) => {
        if (response?.status) {
          handleCloseModel();
          getOrganizationData();
          WalToast.success(
            'Member Role Updated',
            'Member role was successfully updated.'
          );
        } else {
          WalToast.error(
            'Error: Role Update',
            'Error encountered while updating role. Please try again.'
          );
        }
      })
      .finally(() => {
        setIsEditMemberLoading(false);
      })
      .catch(() => {
        setIsEditMemberLoading(false);
        WalToast.error(
          'Error: Role Update',
          'Error encountered while updating role. Please try again.'
        );
      });
  };

  const handleSelectChange = (e: any) => {
    setMember({ ...member, role: e?.value });
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between m-4 mb-0 pb-4 xxl:pb-4 xxl:mx-5 border-b">
        <div className="w-full mb-4 md:mb-0 md:w-[30%]">
          <div className="">
            <p className="text-sm text-[#344054] font-semibold">
              Organization Members
            </p>
            <p className="text-xs text-grayLight600 font-normal">
              Manage your organization member
            </p>
          </div>
        </div>
        <div className="w-full md:w-[70%] bg-white border border-gray100 overflow-x-auto shadow-sm rounded-xl overflow-hidden min-h-[380px] ">
          <div className="flex flex-col lg:flex-row border-b lg:justify-between lg:items-center bg-white px-5 py-3  w-full gap-2">
            <div className="flex flex-col">
              <div className="flex justify-start items-center gap-1.5">
                <span className="table-title">All Organization Members</span>
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={`${isMemberLoading ? 'custom-loading' : ''}`}
                >
                  {totalMembers} {totalMembers > 1 ? 'Members' : 'Member'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                View, add, and organize your team members
              </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 mt-3 md:mt-0">
              <ButtonCmp
                type="button"
                className="w-full md:w-max btn-outline-primary"
                onClick={() => setOpenAddOrgMember(true)}
              >
                <Plus className="w-4 mr-0.5" />
                <span className="font-semibold">Invite team member</span>
              </ButtonCmp>
            </div>
          </div>
          {/* <div className="flex flex-col lg:flex-row border-b lg:justify-between lg:items-center bg-white px-5 py-3  w-full gap-2"> */}
          <div className="table-bottom-header">
            <div className="table-header-bottom-left ">
              <InputText
                inputName="searchTeam"
                placeholder="Search"
                value={searchInput}
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                onChangeFunc={handleAdminsFilter}
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                isClearable={true}
                parentClassName="table-searchInput sm:min-w-[auto] min-w-full"
              />
            </div>

            <div className="table-statustbox">
              <SelectBox
                name="role"
                placeholder="Filter by Role"
                id="role"
                className="form_control shadow"
                size="sm"
                options={userRole}
                onChangeFunc={(event: any) => {
                  setAdminParams((old: any) => ({
                    ...old,
                    role: event?.value || '',
                  }));
                }}
                isSearchable={false}
                isClearable
                // value={null}
                value={
                  userRole.find(
                    (val: any) => val?.value === adminParams?.role
                  ) || null
                }
              />
              {adminParams?.role}
            </div>

            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === adminParams.limit
                )}
                onChangeFunc={(event: any) => {
                  setAdminParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
              />
            </div>
          </div>
          {/* </div> */}
          <div className=" ">
            <div className=" overflow-x-auto  w-2/2 flex-1   shadow-sm rounded-xl flex">
              <div className="flex-1 border-t border-gray100">
                <div className="overflow-x-auto custom-scrollbar">
                  <TableCmp
                    headCells={headCells}
                    tableDataArr={orgMember}
                    TableLoaderRowCmp={LoadingRow}
                    TableRowCmp={MemberRow}
                    params={adminParams}
                    setParams={setAdminParams}
                    tableRowCmpProps={{
                      handleActionType: handleActionType,
                    }}
                    isTableDataLoading={isMemberLoading}
                    numberOfSkeletonRows={adminParams.limit}
                    isTableRowClickable={false}
                  />
                </div>
              </div>

              {!orgMember.length && !isMemberLoading ? (
                <NotFoundUI
                  title="No members found"
                  desc="There are not found any organization member in your organization, you can add a new one."
                  buttonText={`Add New Member`}
                  handleActionType={() => true}
                  buttonDisabled={false}
                />
              ) : (
                ''
              )}
            </div>
            {totalMembers ? (
              <div className="w-full ">
                <CustomPagination
                  recordsPerPage={adminParams.limit}
                  totalRecords={totalMembers}
                  currentPage={adminParams.page}
                  handlePagination={handleAdminsPagination}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {action.mode === 'member-edit' && (
        // <CommonModal
        //   handleClose={() => handleCloseModel()}
        //   size={'max-w-lg w-[90%]'}
        // >
        <CommonModal
          title={'Edit Member'}
          titleDesc={
            'Edit an organization  member for change role and permissions.'
          }
          handleClose={handleCloseModel}
          headerIcon={<UserPlus01 />}
          secondaryBtnOnClick={() => handleCloseModel()}
          size={'max-w-[488px]'}
          primaryBtnText={'Save changes'}
          primaryBtnOnClick={handleEditMember}
          primaryBtnLoading={isEditMemberLoading}
          secondaryBtnText="Cancel"
          isOverflow={true}
        >
          <div className="">
            <div className="flex flex-col md:flex-row p-3.5 md:p-4 xxl:p-5">
              <div className="w-full md:w-[70%]">
                <label className={`form_label`}>Email address</label>
                <div className="relative">
                  <InputText
                    inputName="email"
                    className="appearance-none block pl-[38px] h-[40px] placeholder:text-gray500 text-sm font-normal w-full input_border shadow-sm placeholder:text-sm text-[#000000E5] placeholder:font-normal"
                    placeholder="you@untitledui.com"
                    value={member?.email || ''}
                    disabled={true}
                  />
                  {/* <img
                    src={emailicon}
                    alt="mailicon"
                    className="absolute left-3 top-2.5"
                  /> */}
                </div>
              </div>
              <div className="w-full md:w-[30%] md:pl-3 cursor-pointer select_box_main">
                <label className={`form_label`}>Role</label>
                <SelectBox
                  name="role"
                  placeholder="Select Role"
                  className={`form_control cursor-pointer rounded-md `}
                  options={userRole}
                  onChangeFunc={(e) => handleSelectChange(e)}
                  value={userRole?.find(
                    (val: any) => val?.value === member?.role
                  )}
                />
              </div>
            </div>
          </div>
        </CommonModal>
      )}

      {action.mode === 'member-delete' && (
        <DeleteModal
          moduleName={`${member.name ?? ''} from Organization ${
            member?.name ?? ''
          }`}
          handleClose={handleModalClose}
        />
      )}

      {openAddOrgMember && (
        <AddMember
          handleCloseMember={handleCloseMember}
          getOrganizationData={getOrganizationData}
        />
      )}
    </>
  );
};

export default memo(OrganizationMembers);
