import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const fetchOrganizationMemberList = async (params: any) => {
  const response = await axiosInterceptor.get(`${API.MEMBER.MEMBER_LIST}`, {
    params,
  });

  return response;
};

export const editMember = async (payload: any) => {
  const response = await axiosInterceptor.post(
    `${API.MEMBER.MEMBER_EDIT}`,
    payload
  );

  return response;
};

export const inviteMember = async (payload: any) => {
  const response = await axiosInterceptor.post(
    `${API.MEMBER.MEMBER_INVITE}`,
    payload
  );

  return response;
};

export const deleteMember = async (id: any) => {
  const response = await axiosInterceptor.delete(
    `${API.MEMBER.MEMBER_DELETE}/${id}`
  );

  return response;
};

// export const fetchAllOrganizationMembers = async (orgId?: any) => {
//   const response = await axiosInterceptor.get(`${API.ORGANIZATION.ALL_MEMBERS}?${orgId}`);
//   return response;
// };

// export const updateOrganization = async (payload: any) => {
//   const response = await axiosInterceptor.patch(`${API.ORGANIZATION.UPDATE_ORGANIZATION}`, payload);
//   return response;
// };
// export const updateOrganizationMember = async (orgMemberId: any, payload: any) => {
//   const response = await axiosInterceptor.patch(`${API.ORGANIZATION.UPDATE_ORGANIZATION_MEMBER}/${orgMemberId}`, payload);
//   return response;
// };
// export const removeOrganizationMember = async (queryParams: any) => {
//   const response = await axiosInterceptor.delete(`${API.ORGANIZATION.DELETE_ORGANIZATION_MEMBER}`, { data: queryParams } as any);
//   return response;
// };
