import {
  ChevronDown,
  HomeLine,
  SearchLg,
  Menu02,
  XClose,
  ChevronUp,
  ChevronSelectorVertical,
  Truck01,
  Building06,
} from '@untitled-ui/icons-react/build/cjs';
import React, {
  Fragment,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import { BasicContext } from 'src/context/BasicContext';
// import { setUserStatus, switchUserRole } from 'src/services/_UserService';

import LogoDark from '../../assets/img/logo-dark.png';
import LogoSmall from '../../assets/img/logo-small.png';
import InputText from '../../components/InputText/InputText';
import { USER_ROLE } from '../../constants/common';
import { ROUTES } from '../../constants/routes';
import { AuthContext } from '../../context/AuthContext';
import {
  getShortName,
  onError,
  useRolePermission,
} from '../../utils/CommonFunctions';

const Sidebar = () => {
  const useIdNum = useId();
  const { pathname } = useLocation();
  const { hasRoleV2 } = useRolePermission();
  const { isAuthenticated, currentUser, setIsAuthenticated } =
    useContext(AuthContext);
  const { appearance } = useContext(BasicContext);
  const [hiddenModule, setHiddenModule] = useState<any>([]);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const [isOpenSettingMenu, setIsOpenSettingMenu] = useState(false);
  const checkMode =
    window.MODE === 'production' || window.MODE === 'staging' ? false : true;

  const divRef = useRef<any>(null);

  const mainOptions = useMemo(
    () => [
      {
        path: ROUTES.HOME,
        displayName: 'Home',
        Icon: HomeLine,
        permission: true,
      },
      {
        path: ROUTES.MY_QUOTE,
        displayName: 'My Quote',
        Icon: Truck01,
        permission: true,
      },
      {
        path: ROUTES.ORGANIZATION,
        displayName: 'Organization',
        Icon: Building06,
        permission: true,
      },
    ],
    []
  );

  const bottomOptions = useMemo(() => [], []);

  const dropdownOptions = useMemo(() => [], []);

  useEffect(() => {
    if (window.MODE === 'staging') {
      setHiddenModule(['Lane History', 'Rate Shopping', 'Operations']);
    } else if (window.MODE === 'production') {
      let listModule = [
        // 'Credit Dashboard',
        'Requests',
        'Lane History',
        'Rate Shopping',
        'Operations',
        'Targets',
        'Integrations',
        'Geography',
        'Radar',
        // 'Load Insurance',
      ];

      if (
        hasRoleV2(USER_ROLE.ADMIN) ||
        hasRoleV2(USER_ROLE.MANAGER) ||
        hasRoleV2(USER_ROLE.SALES)
      ) {
        listModule = [
          // 'Credit Dashboard',
          'Requests',
          'Lane History',
          'Rate Shopping',
          'Operations',
          'Integrations',
          'Geography',
          'Radar',
          // 'Load Insurance',
        ];
      }
      setHiddenModule(listModule);
    } else {
      setHiddenModule([]);
    }

    // setInterval(getRate, 30 * 60 * 1000);
  }, []);

  const [containerHeights, setContainerHeights] = useState<any>({});

  const toggleHeight = (index: string, isKeepOpen: boolean = false) => {
    const updatedContainerHeights = {} as any; // keep single submenu open
    updatedContainerHeights[index] =
      !!containerHeights[index] && !isKeepOpen
        ? 0
        : document.getElementById(`container${index}`)?.scrollHeight;
    setContainerHeights(updatedContainerHeights);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
  };

  const matchPattern = (pattern: any, subPath: any) => {
    const patternSegments = pattern.split('/');
    const pathnameSegments = subPath.split('/');

    const patternLength = patternSegments.length;
    const pathnameLength = pathnameSegments.length;

    if (pathnameLength < patternLength || pathnameLength > patternLength)
      return false;

    for (let i = 0; i < patternLength; i++) {
      const patternSegment = patternSegments[i];
      const pathnameSegment = pathnameSegments[i];

      if (patternSegment.startsWith(':')) continue;

      if (patternSegment !== pathnameSegment) return false;
    }

    return true;
  };

  const handleMenuAndSubMenuActivation = () => {
    mainOptions.forEach((mainOption: any) => {
      const pathNameMain = pathname.split('/').filter((ptn) => ptn);
      const isSubActive = mainOption.subMenu
        ? mainOption.subMenu.find(
            (item: any) =>
              (pathname === '/' &&
                item.subPath === '/quotes/dashboard' &&
                !checkMode) ||
              item.subPath === pathname ||
              (item.subPath === '/' && item.subPathAlt === pathNameMain?.[0]) ||
              (item.subPath === '/' && pathNameMain?.[0] === 'login') ||
              item.childPathNames?.includes(pathname) ||
              item.childPathNames?.some((child: any) =>
                matchPattern(child, pathname)
              )
          )
        : undefined;

      if (isSubActive) {
        toggleHeight(mainOption.displayName, true);
      }
    });
  };

  useEffect(() => {
    setIsSideMenuOpen(false);
    handleMenuAndSubMenuActivation();
  }, [pathname]);

  const handleClickOutside = (event: any) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsOpenSettingMenu(false);
    }
  };

  useEffect(() => {
    if (isOpenSettingMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenSettingMenu]);

  return (
    <aside
      className="absolute top-0 left-0 flex flex-col md:w-[var(--layout-sidebar-width)] w-full bg-utilityGray300 md:h-full md:pt-[var(--layout-top-padding)]"
      aria-label="SideNav"
    >
      <div className="md:pb-1 md:pt-6 md:px-[11px] px-4 py-3 flex items-center gap-3 justify-between  md:z-auto">
        <div className="flex items-end md:justify-between w-full">
          <Link to={ROUTES.HOME}>
            {/* <img
              src={LogoDark}
              alt="logo-light"
              className="lg:w-[180px] w-[160px] h-[36px] object-cover md:pl-2 md:pt-[2px] md:inline-block hidden"
            /> */}

            {appearance?.logo ? (
              <>
                <img
                  src={appearance?.logoUrl + appearance?.logo}
                  alt="logo-light"
                  className="lg:w-[180px] w-[160px] h-[36px] object-cover md:pl-2 md:pt-[2px] md:inline-block hidden"
                />
                <img
                  src={appearance?.logoUrl + appearance?.logo}
                  alt="logo-light"
                  className="w-11 md:pl-2 md:pt-[2px] md:hidden z-[7] relative"
                />
              </>
            ) : (
              <>
                <img
                  src={LogoDark}
                  alt="logo-light"
                  className="lg:w-[180px] w-[160px] h-[36px] object-cover md:pl-2 md:pt-[2px] md:inline-block hidden"
                />
                <img
                  src={LogoSmall}
                  alt="logo-light"
                  className="w-11 md:pl-2 md:pt-[2px] md:hidden z-[7] relative"
                />
              </>
            )}
          </Link>
          <span className="text-[8px] items-end font-medium text-[#667085]">
            v2.0
          </span>
        </div>
        <ButtonCmp
          className="md:hidden rounded-lg border border-grayLight600 p-2 flex justify-center items-center"
          onClick={() => setIsSideMenuOpen((prev) => !prev)}
        >
          <Menu02 className="text-grayLight600" />
        </ButtonCmp>
      </div>
      <div
        key={`sidebar_${useIdNum}`}
        className={`flex-grow overflow-hidden flex flex-col md:relative fixed top-0  md:h-auto h-full z-[14] md:z-auto md:w-full md:[&>div]:w-full [&>div]:w-[280px] [&>div]:bg-utilityGray300  md:[&:not(.menu-open)]:right-[unset] [&:not(.menu-open)]:right-[-280px] [&.menu-open]:right-0 transition-all duration-[0.5s] [&.menu-open]:after:content-[''] [&.menu-open]:after:bg-black [&.menu-open]:after:opacity-50 [&.menu-open]:after:fixed [&.menu-open]:after:left-0 [&.menu-open]:after:h-full [&.menu-open]:after:w-full [&.menu-open]:after:-z-[1] [&.menu-open]:after:top-[var(--layout-top-padding)] after:opacity-0 after:transition-all after:duration-[0.5s] md:after:hidden ${
          isSideMenuOpen ? 'menu-open' : ''
        }`}
      >
        <div className="pb-1 pt-[9px] px-[11px] md:hidden flex justify-end">
          <ButtonCmp
            className="md:hidden rounded-lg border border-grayLight600 p-2 flex justify-center items-center"
            onClick={() => setIsSideMenuOpen((prev) => !prev)}
          >
            <XClose className="text-grayLight600" />
          </ButtonCmp>
        </div>
        <div className="py-6 px-3">
          <InputText
            inputName="search"
            placeholder="Search"
            className="pl-[26px] placeholder:text-secondaryTxtColor border-gray400"
            icon={
              <SearchLg className="absolute -translate-y-2/4 top-2/4 left-[7px] text-grayText h-4 w-4" />
            }
          />
        </div>
        <div className="flex-grow overflow-y-auto px-3 scrollbar-hide">
          <ul className="space-y-2">
            {mainOptions.map((option: any, index: number) => {
              const {
                hasSubMenu,
                path,
                Icon,
                displayName,
                permission,
                subMenu,
              } = option;
              const pathNameMain = pathname.split('/').filter((ptn) => ptn);
              const isSubActive = subMenu
                ? subMenu.find(
                    (item: any) =>
                      (pathname === '/' &&
                        item.subPath === '/quotes/dashboard' &&
                        !checkMode) ||
                      item.subPath === pathname ||
                      item.subPath === pathNameMain ||
                      (item.subPath === '/' &&
                        item.subPathAlt === pathNameMain?.[0]) ||
                      item.childPathNames?.includes(pathname) ||
                      item.childPathNames?.some((child: any) =>
                        matchPattern(child, pathname)
                      )
                  )
                : undefined;

              if (!permission || hiddenModule.includes(displayName)) {
                return (
                  <Fragment key={`nav_${displayName}_${index}`}></Fragment>
                );
              }

              return (
                <li key={`nav_${displayName}_${index}`} className="relative">
                  {hasSubMenu ? (
                    <div
                      onClick={() => toggleHeight(displayName)}
                      className={`wal__sidebar_link ${
                        isSubActive && 'active-nav bg-utilityGray400'
                      }`}
                    >
                      <div className="w-full flex justify-between items-center">
                        <div className="flex items-center">
                          <Icon
                            className={`link_icon ${
                              isSubActive && 'active_icon'
                            }`}
                          />
                          <span className="ml-2.5">{displayName}</span>
                        </div>
                        {containerHeights[displayName] ? (
                          <ChevronUp className="h-3.5 w-3.5" />
                        ) : (
                          <ChevronDown className="h-3.5 w-3.5" />
                        )}
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to={path}
                      onClick={() => toggleHeight(displayName)}
                      className={({ isActive }) =>
                        `wal__sidebar_link ${
                          (isActive || isSubActive?.subPath === pathname) &&
                          'active-nav'
                        }`
                      }
                      // eslint-disable-next-line react/no-children-prop
                      children={({ isActive }) => (
                        <div className="w-full flex items-center">
                          <Icon
                            className={`link_icon ${
                              (isActive || isSubActive?.subPath === pathname) &&
                              'active_icon'
                            }`}
                          />
                          <span className="ml-3 flex-1">{displayName}</span>
                        </div>
                      )}
                    />
                  )}

                  {subMenu && hasSubMenu && (
                    <div
                      className={`relative overflow-hidden transition-all duration-100 pl-1 ml-5`}
                      style={{ maxHeight: containerHeights[displayName] || 0 }}
                      id={`container${displayName}`}
                    >
                      {subMenu
                        .filter(
                          (item: any) =>
                            item.subPermission &&
                            !hiddenModule.includes(item.subDisplayName)
                        )
                        .map((item: any, idx: number) => {
                          const { subPath, subDisplayName } = item;
                          const subMenuLength = subMenu.filter(
                            (itemVal: any) =>
                              itemVal.subPermission &&
                              !hiddenModule.includes(itemVal.subDisplayName)
                          )?.length;

                          return (
                            <div
                              key={`sub_nav_${subPath}`}
                              className={`first:pt-2 flex items-center sticky`}
                            >
                              <div
                                className={`h-2 w-2 flex-none ${
                                  isSubActive?.subPath === subPath
                                    ? 'bg-grayLight900'
                                    : 'bg-gray500'
                                }  rounded wal__sidebar_sub_line_container ${
                                  idx === 0 ? 'is_first_child' : ''
                                } ${
                                  idx + 1 === subMenuLength
                                    ? 'is_last_child'
                                    : ''
                                }`}
                              ></div>
                              <NavLink
                                to={subPath}
                                className={`wal__sidebar_sub_link ${
                                  isSubActive?.subPath === subPath &&
                                  'text-grayLight900'
                                }`}
                              >
                                <span className="ml-[5px]">
                                  {subDisplayName}
                                </span>
                              </NavLink>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="px-3.5 pb-4">
          <div className="content-end">
            <ul className="flex flex-col gap-1 pb-4 pt-2">
              {bottomOptions.map((option: any) => {
                const { path, Icon, displayName, permission } = option;

                return (
                  <li
                    key={`bottom_nav_${path}`}
                    className={`${permission ? 'visible' : 'hidden'}`}
                  >
                    <NavLink
                      to={path}
                      className={({ isActive }) =>
                        `wal__sidebar_link ${isActive && 'active-nav'}`
                      }
                      // eslint-disable-next-line react/no-children-prop
                      children={({ isActive }) => (
                        <>
                          <Icon
                            className={`link_icon ${isActive && 'active_icon'}`}
                          />
                          <span className="ml-2.5">{displayName}</span>
                        </>
                      )}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="border-t border-utilityGray200">
              <div
                className="flex mt-3 px-2 py-3 items-center w-full gap-2  cursor-pointer bg-white hover:bg-utilityGray100 border border-utilityGray200 rounded-xl"
                onClick={() => {
                  setIsOpenSettingMenu(!isOpenSettingMenu);
                }}
                ref={divRef}
              >
                {currentUser?.image ? (
                  <img
                    src={`${currentUser?.imageUrl}${currentUser?.image}`}
                    className="w-10 h-10 object-cover rounded-full cursor-pointer border-[0.75px] border-black/[0.08] flex-none "
                    alt={isAuthenticated && `${currentUser?.name} `}
                    title={isAuthenticated && `${currentUser?.name}`}
                    onError={onError}
                  />
                ) : (
                  <div className="w-9 h-9 text-sm rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none">
                    {getShortName(`${currentUser?.name}`)}
                  </div>
                )}

                <div className=" flex-grow overflow-hidden">
                  <div className="text-grayLight600 text-xs font-normal truncate">
                    {currentUser?.email}
                  </div>
                </div>
                <div className="flex items-center cursor-pointer flex-none self-start">
                  {/* <LogOut02 className="text-gray400 w-5 h-5" /> */}
                  <ChevronSelectorVertical className="text-gray400 w-5 h-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`rounded-lg border border-utilityGray200 bg-white shadow-lg -right-[259px] w-[270px] bottom-4 z-[22] absolute overflow-hidden px-1.5 ${
          isOpenSettingMenu ? '' : 'hidden'
        }`}
        ref={divRef}
      >
        <ul className="py-1.5">
          {dropdownOptions &&
            dropdownOptions.map((option: any) => {
              const { path, displayName, permission } = option;

              return (
                <>
                  <NavLink
                    key={`dropdown_nav_${path}`}
                    to={path}
                    className={`flex items-center gap-x-2 mx-1.5 px-3 py-1.5 cursor-pointer hover:bg-gray50 [&.active]:bg-gray50 rounded-md first:mt-0 mt-1   ${
                      permission ? 'visible' : 'hidden'
                    }`}
                    onClick={() => {
                      setIsOpenSettingMenu(false);
                    }}
                  >
                    {/* <Icon className="w-4 text-gray500" /> */}
                    <p className="text-utilityGray800 text-sm font-medium">
                      {displayName}
                    </p>
                  </NavLink>
                </>
              );
            })}
          <div
            className="flex items-center gap-x-2 mx-1.5 px-3 py-1.5 rounded-md cursor-pointer hover:bg-gray50 mt-1"
            onClick={handleLogout}
          >
            <p className="text-utilityGray800 text-sm font-medium">Sign out</p>
          </div>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
